'use client';

import { useEditAddressMutation } from '@akinon/next/data/client/address';
import { useLocalization } from '@akinon/next/hooks';
import { FormModal } from '@theme/components/form-modal';
import React from 'react';
import { AddressForm } from '../account';
import { useAddress } from '@theme/hooks';
import { Address } from '@akinon/next/types';
import { getCookie, setCookie } from '@akinon/next/utils';

interface AddressModalProps {
  isEditAddressModalOpen?: boolean;
  setIsEditAddressModalOpen?: (value: boolean) => void;
  address: Address;
}

const EditAddressModal = ({
  isEditAddressModalOpen,
  setIsEditAddressModalOpen,
  address
}: AddressModalProps) => {
  const { t } = useLocalization();
  const { setSelectedAddress, unsetPrimaryAddress } = useAddress();
  const [editAddress] = useEditAddressMutation();

  const onSubmit = async (addressData) => {
    try {
      const storedAddressPk = getCookie('selectedAddress');
      const wasSelected =
        storedAddressPk && Number(JSON.parse(storedAddressPk)) === address.pk;

      const wasPrimary =
        (address?.extra_field as { address_primary: string })
          ?.address_primary === 'true';

      const newAddress = await editAddress({
        ...addressData,
        address_primary: wasPrimary ? 'true' : 'false'
      }).unwrap();

      if (wasPrimary && !addressData.address_primary) {
        await unsetPrimaryAddress();
      }

      if (wasSelected) {
        setSelectedAddress(newAddress);
        setCookie('selectedAddress', newAddress.pk.toString());
      }

      setIsEditAddressModalOpen(false);
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };

  return (
    <FormModal
      portalId={`account-address-edit-address-modal`}
      title={t('account.address_book.modal.edit_address')}
      open={isEditAddressModalOpen}
      setOpen={setIsEditAddressModalOpen}
      className="flex w-full flex-col overflow-y-auto sm:w-[28rem]"
      childrenClassName="h-full"
    >
      <AddressForm
        data={{
          ...address,
          country: address?.country?.pk,
          city: address?.city?.pk,
          district: address?.district?.pk,
          ffc_last_updated_datetime: new Date().toISOString(),
          address_primary:
            (address?.extra_field as { address_primary: string })
              ?.address_primary === 'true'
        }}
        onSubmit={onSubmit}
        setIsEditAddressModalOpen={setIsEditAddressModalOpen}
      />
    </FormModal>
  );
};

export default EditAddressModal;
